import {
  getCategories,
  getIntelligenceSearch,
  QueryClient,
  getProducts
} from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'
import { GetServerSidePropsContext } from 'next'
import { paginationCal } from './paginationCal'

interface GetServerSidePropsCategory {
  categories: string | string[];
  categoryData: Data;
  intelliSearchData?: null;
  error: boolean;
  queryClient: QueryClient;
}

export const normalizeCategory = (category: string) => {
  return category
    .normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/,/g, '')
    ?.replace(/\s/g, '-')
    .toLowerCase()
}

export const changeUrlCategories = (breadcrumbData) => {
  const breadcrumb = breadcrumbData?.map?.((breadcrumbItem) => {
    if(breadcrumbItem?.label !== 'Inicio'){
      breadcrumbItem['url'] = `/category${breadcrumbItem?.url}`
    }
    return breadcrumbItem
  })

  breadcrumb[breadcrumbData?.length-1]['url'] = '#'
  const { label, url} = breadcrumb[breadcrumbData?.length-1]
  breadcrumb[breadcrumbData?.length-1] = {
    label,
    url
  }
  return breadcrumb
}

export const categoriesForBreadcrumbs = ({ router, categories, resultAisles }) => {
  let urlCategory = ''
  let categoryId = ''
  let subCategories = []
  let categoryTemp = resultAisles?.data || []
  const handleOnClickBreadCrumbs = (url: string) => { return router.push(url) }

  const breadcrumbData = categories?.map(key => {
    const data = categoryTemp?.find(({ name }) => { 
      return normalizeCategory(name) === normalizeCategory(key)
    })
    categoryId = data?.id
    categoryTemp = data?.children.length > 0 ? data.children : data
    urlCategory += `/${key}`
    subCategories = data?.children?.map(({ name }) => {
      return {
        label: name,
        url: `${urlCategory}/${normalizeCategory(name)}`
      }
    })

    return {
      label: data?.name,
      url: urlCategory,
      oneClick: handleOnClickBreadCrumbs
    }
  }) || []
  breadcrumbData?.unshift({ label: 'Inicio', url: '/', oneClick: handleOnClickBreadCrumbs })
  const title = breadcrumbData[breadcrumbData?.length - 1].label

  return {
    breadcrumbData,
    subCategories,
    title,
    categoryId
  }
}

export const getServerSidePropsCategory = async (context: GetServerSidePropsContext): Promise<GetServerSidePropsCategory> => {
  const queryClient = new QueryClient()
  const checkQueryArray = Array.isArray(context?.query?.categories) ? context?.query?.categories : [context?.query?.categories]

  // get categories and save it in temporal variable
  const categories = await getCategories(2, 'alvi') as Data[]
  let temporalCategories = categories 

  // we extract the category match id here, e.g 349 (meet)
  const checkCategory: Data = checkQueryArray.map(key => {
    const categoryMatch = temporalCategories?.find(({ name }) => {
      return normalizeCategory(name) === normalizeCategory(key)
    })
    temporalCategories = categoryMatch?.children.length > 0 ? categoryMatch?.children : [categoryMatch]
    
    return categoryMatch
  }).slice(-1)[0]
  
  // if there is no data then an error is triggered
  if (!categories || categories.length === 0) {
    return {
      categories: context.query.categories,
      categoryData: null,
      intelliSearchData: null,
      error: true,
      queryClient
    }
  }
  if (checkCategory) {
    const prodOptions = { fq: checkCategory?.fq, from: 0, to: 49 }
    await queryClient.prefetchQuery('products', () => {
      return getProducts(prodOptions)
    })
  }

  return {
    categories: context.query.categories,
    categoryData: { ...checkCategory },
    error: false,
    queryClient
  }
}

export const getSearchData = async ({query, page}) => {
  const queryClient = new QueryClient()

  const intelliSearchKeyName = `intelligencesearch_${query}`
  const {offset, end } = paginationCal({page,limit:50})

  const intelliOptions = { search: query, from: offset,to: end }

  await queryClient.prefetchQuery(intelliSearchKeyName, () => {
    return getIntelligenceSearch(intelliOptions)
  })
  const intelliSearchData = queryClient.getQueryData(intelliSearchKeyName)

  return {
    intelliSearchData,
    queryClient
  }

}
